/* cmd + shift + P   ->   Change language mode   ->   tailwindcss (to get rid of yellow underline) */

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 2em auto;
  margin-top: 0px;
  max-width: 1200px;
  line-height: 1.6;
  font-size: 18px;
  color: #444;
  padding: 1em;
  padding-top: 0px;
  font-family: 'Courier New', Courier, monospace;/* 'Helvetica', 'Arial', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3 {
  line-height: 1.2
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
